import { useEffect, useState } from "react"

export const useRefResize = ref => {
    const [container, setContainer] = useState({ w: 0, h: 0 })

    useEffect(() => {
        const resize = () =>
            setContainer({
                w: ref.current?.clientWidth,
                h: ref.current?.clientHeight,
            })

        if (ref.current) {
            resize()
            window.addEventListener("resize", resize)
        }
        return () => window.removeEventListener("resize", resize)
    }, [])

    return container
}
