import { Chat } from "@shiftx/icons"
import { css } from "@shiftx/styles"
import { mockActors } from "./MockActors"
import parse from "html-react-parser"

const getActorsFromNarrative = narrative => {
    const actorsInNarrative = []
    parse(narrative ?? "", {
        replace: dom => {
            if (dom.name === "actor") {
                const actor = mockActors?.find(
                    actor => actor.id === dom.attribs.id
                )
                if (actor) {
                    actorsInNarrative.push(actor)
                }
            }
        },
    })

    return actorsInNarrative
}

const MockHtmlNarrative = ({ narrative, ...props }) => {
    return (
        <div
            {...props}
            css={css`
                width: 240px;
                text-align: center;
                p {
                    margin-block-start: 0;
                    margin: 0;
                }
            `}
        >
            {parse(narrative ?? "", {
                replace: dom => {
                    if (dom.name === "actor") {
                        const actor = mockActors?.find(
                            actor => actor.id === dom.attribs.id
                        )
                        return (
                            <span
                                css={css`
                                    color: ${actor.color};
                                    font-weight: 600;
                                `}
                                {...dom.attribs}
                            >
                                {actor.name}
                            </span>
                        )
                    }
                },
            })}
        </div>
    )
}

const MockActor = ({ actor, ...props }) => {
    return (
        <div
            css={css`
                display: grid;
                place-content: center;
                flex-shrink: 0;
                height: 48px;
                width: 48px;
                left: 142px;
                top: 302px;
                border-radius: 50%;
                border: 2px solid white;
                color: white;
                background-color: var(--orange-500);
            `}
            {...props}
        >
            {actor.comp}
        </div>
    )
}

interface MockStepProp {
    duration?: {
        value: number
        unit: "sec" | "min" | "hrs"
    } | null
    chat?: number
    scale?: number
    narrative: string
    hideNarrative?: boolean
    selected?: boolean
    css?: any
}

export const MockStep = ({
    duration,
    chat,
    narrative,
    hideNarrative = false,
    selected = false,
}: MockStepProp) => {
    const actorsInNarrative = getActorsFromNarrative(narrative)
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                user-select: none;
            `}
        >
            {duration && (
                <div
                    css={css`
                        display: flex;
                        color: var(--fg-medium);
                        background-color: var(--fg-100);
                        padding: 4px 8px;
                        border-radius: 12px;
                        height: 24px;
                        font-family: Inter;
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: center;
                    `}
                >
                    {`${duration.value} ${duration.unit}`}
                </div>
            )}
            <div
                css={css`
                    position: relative;
                    display: flex;
                    border-radius: 29px;
                    background-color: white;
                    border: 2px solid transparent;
                    box-shadow: 0px 0px 20px 0px var(--fg-300);
                    padding: 3px;
                    transition: all 0.3s ease;
                    ${selected &&
                    css`
                        border: 2px solid var(--blue-500);
                        padding: 3px;
                    `}
                    :hover {
                        border-color: var(--blue-500);
                    }
                `}
            >
                {actorsInNarrative.map((actor, index) => {
                    return (
                        <MockActor
                            actor={actor}
                            key={index}
                            css={css`
                                background-color: ${actor.color};
                                ${index === 0 &&
                                css`
                                    z-index: 2;
                                `}
                                ${index === 1 &&
                                css`
                                    margin-left: -8px;
                                    z-index: 1;
                                `}
                                        ${index === 2 &&
                                css`
                                    margin-left: -8px;
                                    z-index: 0;
                                `}
                            `}
                        />
                    )
                })}
            </div>
            {!hideNarrative && <MockHtmlNarrative narrative={narrative} />}
            {chat > 0 && (
                <div
                    css={css`
                        display: flex;
                        gap: 4px;
                        color: var(--blue-500);
                        background-color: var(--blue-200);
                        padding: 4px 6px;
                        border-radius: 4px;
                        height: 24px;
                        font-family: Inter;
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: center;
                    `}
                >
                    <Chat width={16} height={16} />
                    {chat}
                </div>
            )}
        </div>
    )
}
