import { css } from "@shiftx/styles"
import { motion } from "framer-motion"
import { useRef } from "react"
import { MockStep } from "../Mock/MockStep"
import { Check, ChevronFilledRight } from "@shiftx/icons"
import { useRefResize } from "../../hooks/useRefResize"

export const Grid = ({ ...rest }) => {
    return (
        <motion.div
            css={css`
                position: absolute;
                width: 500px;
                height: 500px;
                left: 0;
                top: 0;
            `}
            {...rest}
        >
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    background-size: 40px 40px;
                    background-image: linear-gradient(
                            to right,
                            var(--fg-200) 1px,
                            transparent 1px
                        ),
                        linear-gradient(
                            to bottom,
                            var(--fg-200) 1px,
                            transparent 1px
                        );
                    mask-image: radial-gradient(
                        circle at center,
                        #000 30%,
                        transparent 70%
                    );
                `}
            />
        </motion.div>
    )
}

const Summary = ({ scale, ...rest }) => {
    return (
        <motion.div
            {...rest}
            css={css`
                transform-origin: center;
                width: 500px;
                height: 500px;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    left: 250px;
                    top: 250px;
                    transform: translate(-50%, -50%) scale(${scale});
                    transform-origin: center;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        font-family: "Albert Sans";
                        font-size: 14px;
                        padding: 16px 16px;
                        border-radius: 4px;
                        background-color: white;
                        border: solid 1px var(--fg-300);
                        box-sizing: content-box;
                        box-shadow: 0px 0px 24px rgba(24, 25, 32, 0.08);
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            font-weight: 500;
                            font-size: 15px;
                            margin-bottom: 16px;
                        `}
                    >
                        <div
                            css={css`
                                display: grid;
                                place-items: center;
                                width: 20px;
                                height: 20px;
                                background-color: var(--green-500);
                                border-radius: 50%;
                                svg {
                                    color: white;
                                }
                            `}
                        >
                            <Check width={12} height={12} />
                        </div>
                        Summary
                    </div>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 200px;
                            margin-bottom: 8px;
                        `}
                    >
                        <span>Duration</span>
                        <span>32 min</span>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 200px;
                        `}
                    >
                        <span>Probability</span>
                        <span>43%</span>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

const DurationInput = ({ scale, ...rest }) => {
    return (
        <motion.div
            {...rest}
            css={css`
                position: absolute;
                transform-origin: center;
                width: 500px;
                height: 500px;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    left: 250px;
                    top: 250px;
                    transform: translate(-50%, -50%) scale(${scale});
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        font-family: "Albert Sans";
                    `}
                >
                    <div
                        css={css`
                            font-weight: 500;
                            font-size: 16px;
                        `}
                    >
                        Duration
                    </div>
                    <div
                        css={css`
                            display: flex;
                            gap: 8px;
                        `}
                    >
                        <div
                            css={css`
                                padding: 6px 12px;
                                border-radius: 4px;
                                background-color: white;
                                width: 180px;
                                border: solid 1px var(--fg-300);
                                box-shadow: 0px 0px 24px rgba(24, 25, 32, 0.08);
                            `}
                        >
                            12
                        </div>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                padding: 6px 12px;
                                border-radius: 4px;
                                background-color: white;
                                border: solid 1px var(--fg-300);
                                box-shadow: 0px 0px 24px rgba(24, 25, 32, 0.08);
                                svg {
                                    transform: rotate(90deg);
                                }
                            `}
                        >
                            <span
                                css={css`
                                    word-break: keep-all;
                                `}
                            >
                                Minutes
                            </span>
                            <ChevronFilledRight width={12} height={12} />
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

const Counter = ({ children, scale, ...rest }) => {
    return (
        <motion.div {...rest}>
            <div
                css={css`
                    position: absolute;
                    left: 250px;
                    top: 120px;
                    transform: translate(-50%, -50%) scale(${scale});
                `}
            >
                <div
                    css={css`
                        word-break: keep-all;
                        font-family: "Albert Sans";
                        font-size: 16px;
                        font-weight: 500;
                        color: var(--green-500);
                    `}
                >
                    {children}
                </div>
            </div>
        </motion.div>
    )
}

export const MockStepAnimation = ({
    duration,
    chat,
    scale = 1,
    narrative,
    hideNarrative = false,
    selected = false,
    ...rest
}) => {
    return (
        <motion.div {...rest}>
            <div
                css={css`
                    position: absolute;
                    left: 250px;
                    top: 250px;
                    transform: translate(-50%, -50%) scale(${scale});
                `}
            >
                <MockStep
                    duration={duration}
                    chat={chat}
                    narrative={narrative}
                    selected={selected}
                    hideNarrative={hideNarrative}
                />
            </div>
        </motion.div>
    )
}

export const TimeAnimation = () => {
    const ref = useRef(null)
    const container = useRefResize(ref)

    const input = {
        visible: {
            opacity: [0, 1, 1, 0],
            y: [0, 0, 0, -50],
            scale: [0.9, 1, 1, 1],
            transition: {
                delay: 0,
                duration: 2,
                repeatDelay: 10,
                times: [0, 0.2, 0.8, 1],
                ease: ["easeOut", "linear", "easeIn"],
                repeat: "loop",
            },
        },
        hidden: {
            opacity: 0,
        },
    }

    const stepAndCount = delay => ({
        stepVisible: {
            opacity: [0, 1, 1, 0],
            x: [50, 0, 0, -50],
            transition: {
                delay,
                duration: 2,
                repeatDelay: 10,
                times: [0, 0.2, 0.8, 1],
                ease: ["easeOut", "linear", "easeIn"],
                repeat: "loop",
            },
        },
        countVisible: {
            opacity: [0, 1, 1, 0],
            y: [20, 0, 0, -20],
            transition: {
                delay,
                duration: 2,
                repeatDelay: 10,
                times: [0, 0.2, 0.8, 1],
                ease: ["easeOut", "linear", "easeIn"],
                repeat: "loop",
            },
        },
        hidden: { opacity: 0 },
    })

    const summary = {
        visible: {
            opacity: [0, 1, 1, 0],
            y: [50, 0, 0, 0],
            scale: [1, 1, 1, 0.9],
            transition: {
                delay: 8,
                duration: 3,
                repeatDelay: 9,
                times: [0, 0.2, 0.8, 1],
                ease: ["easeOut", "linear", "easeIn"],
                repeat: "loop",
            },
        },
        hidden: {
            opacity: 0,
        },
    }

    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                height: auto;
                max-width: 500px;
                max-height: 500px;
            `}
        >
            <div
                ref={ref}
                css={css`
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1;
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        transform: scale(calc((${container.w} / 500)));
                        transform-origin: top left;
                        overflow: hidden;
                    `}
                >
                    {/* <Grid /> */}
                    <DurationInput
                        scale={1}
                        initial="hidden"
                        animate="visible"
                        variants={input}
                    />
                    <Counter
                        initial="hidden"
                        animate="countVisible"
                        variants={stepAndCount(2)}
                        scale={1}
                    >
                        +12
                    </Counter>
                    <MockStepAnimation
                        duration={{ value: 12, unit: "min" }}
                        chat={0}
                        scale={1.2}
                        narrative={
                            '<p><actor id="credit-agency"></actor> receives license overview from <actor id="shiftx"></actor> via <actor id="stripe"></actor></p>'
                        }
                        initial="hidden"
                        animate="stepVisible"
                        variants={stepAndCount(2)}
                    />
                    <Counter
                        initial="hidden"
                        animate="countVisible"
                        variants={stepAndCount(4)}
                        scale={1}
                    >
                        +18
                    </Counter>
                    <MockStepAnimation
                        duration={{ value: 6, unit: "min" }}
                        chat={0}
                        scale={1.2}
                        narrative={
                            '<p><actor id="credit-agency"></actor> updates data against <actor id="population-register"></actor></p>'
                        }
                        initial="hidden"
                        animate="stepVisible"
                        variants={stepAndCount(4)}
                    />
                    <Counter
                        initial="hidden"
                        animate="countVisible"
                        variants={stepAndCount(6)}
                        scale={1}
                    >
                        +32
                    </Counter>
                    <MockStepAnimation
                        duration={{ value: 14, unit: "min" }}
                        chat={0}
                        scale={1.2}
                        narrative={
                            '<p><actor id="credit-agency"></actor> sends out invoice to <actor id="debtor"></actor></p>'
                        }
                        initial="hidden"
                        animate="stepVisible"
                        variants={stepAndCount(6)}
                    />
                    <Summary
                        scale={1.1}
                        initial="hidden"
                        animate="visible"
                        variants={summary}
                    />
                </div>
            </div>
        </div>
    )
}
