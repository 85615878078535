import { css } from "@shiftx/styles"
import { MockMousePointer } from "../Mock/MockMousePointer"

export const MultipleUserCursors = ({
    color1 = "rgba(252, 130, 62, 1)",
    color2 = "rgba(251, 183, 52, 1)",
    darkmode = false,
}: {
    color1?: string
    color2?: string
    darkmode?: boolean
}) => {
    return (
        <div
            css={css`
                position: relative;
                width: 240px;
                height: 120px;
                display: flex;
                align-items: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: var(--bg-primary);
                ${darkmode &&
                css`
                    color: hsla(0deg 0% 0% / 0.87);
                `}
            `}
        >
            <div
                css={css`
                    margin: 0 auto;
                    position: relative;
                    width: 185px;
                    height: 88px;
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        left: 0;
                        top: 30px;
                        padding: 0 12px;
                        width: fit-content;
                        border-radius: 4px;
                        background-color: ${color1};
                    `}
                >
                    Albert
                </div>
                <MockMousePointer
                    css={css`
                        left: 60px;
                        top: 0;
                        transform: rotate(90deg);
                    `}
                    strokeColor={color1}
                    strokeWidth={2}
                    fillColor={!darkmode && "white"}
                    shadow={darkmode}
                />
                <div
                    css={css`
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding: 0 12px;
                        width: fit-content;
                        border-radius: 4px;
                        background-color: ${color2};
                    `}
                >
                    Jane
                </div>
                <MockMousePointer
                    css={css`
                        left: 105px;
                        top: 36px;
                    `}
                    strokeColor={color2}
                    strokeWidth={2}
                    fillColor={!darkmode && "white"}
                    shadow={darkmode}
                />
            </div>
        </div>
    )
}
