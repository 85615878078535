import { css } from "@shiftx/styles"
import { MockStepAnimation } from "./TimeAnimation"
import { MockMousePointer } from "../Mock/MockMousePointer"

export const DragAndDrop = ({ ...rest }) => {
    return (
        <div
            css={css`
                position: relative;
                width: 250px;
                height: 135px;
            `}
            {...rest}
        >
            <MockStepAnimation
                scale={1.1}
                narrative={
                    '<p><actor id="sales"></actor><actor id="robot"></actor></p>'
                }
                hideNarrative={true}
                selected={true}
                css={css`
                    & > div {
                        left: 180px;
                        top: 30px;
                    }
                `}
            />
            <MockMousePointer
                css={css`
                    left: 185px;
                    top: 45px;
                `}
                strokeWidth={2}
            />
            <svg
                width="254"
                height="88"
                viewBox="0 0 254 88"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                    transform: translate(0, 60px);
                `}
            >
                <path
                    d="M0 44L83 44"
                    stroke="url(#paint0_linear_807_22598)"
                    strokeWidth="2"
                />
                <path
                    d="M163 44L254 44"
                    stroke="url(#paint1_linear_807_22598)"
                    strokeWidth="2"
                />
                <g filter="url(#filter0_d_807_22598)">
                    <rect
                        x="84"
                        y="21"
                        width="78"
                        height="46"
                        rx="23"
                        stroke="#467EEB"
                        strokeWidth="2"
                        strokeDasharray="4 4"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_d_807_22598"
                        x="63"
                        y="0"
                        width="120"
                        height="88"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="10" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_807_22598"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_807_22598"
                            result="shape"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_807_22598"
                        x1="0"
                        y1="44"
                        x2="83"
                        y2="44"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#467EEB" stopOpacity="0" />
                        <stop offset="1" stopColor="#467EEB" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_807_22598"
                        x1="163"
                        y1="44"
                        x2="254"
                        y2="44"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#467EEB" />
                        <stop offset="1" stopColor="#467EEB" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}
