import { css } from "@shiftx/styles"
import { greaterThan, lessThan } from "../../utils/breakpoints"
import { SubTitle, Title3 } from "../Text"
import { Badge } from "../Badge"

export type ColorSchemeUSPType = keyof typeof colorSchemeUSP

export const colorSchemeUSP = {
    purple: {
        border: "#eedbf6",
        gradient: "rgba(168, 75, 212, 0.04) 0%, rgba(168, 75, 212, 0) 100%",
        gradientHover: "rgba(168, 75, 212, 0.10) 0%, rgba(168, 75, 212, 0) 50%",
        label: { bg: "#eedbf6;", fg: "#863caa" },
    },
    orange: {
        border: "#fee3d3",
        gradient: "rgba(252, 113, 36, 0.04) 0%, rgba(252, 113, 36, 0) 100%",
        gradientHover: "rgba(252, 113, 36, 0.10) 0%, rgba(252, 113, 36, 0) 50%",
        label: { bg: "#fee3d3", fg: "#ca5a1d" },
    },
    yellow: {
        border: "#FDDEA3",
        gradient: "rgba(250, 173, 24, 0.04) 0%, rgba(254, 239, 209, 0) 100%",
        gradientHover:
            "rgba(250, 173, 24, 0.10) 0%, rgba(254, 239, 209, 0) 50%",
        label: { bg: "rgba(254, 239, 209, 1)", fg: "rgba(200, 138, 19, 1)" },
    },
    blue: {
        border: "#b8cfed",
        gradient: "rgba(36, 137, 252, 0.04) 0%, rgba(36, 137, 252, 0) 100%",
        gradientHover: "rgba(36, 137, 252, 0.10) 0%, rgba(36, 137, 252, 0) 50%",
        label: { bg: "#d3f4fe", fg: "#1d93ca" },
    },
    green: {
        border: "#d3edb8",
        gradient: "rgba(36, 252, 140, 0.04) 0%, rgba(36, 252, 140, 0) 100%",
        gradientHover: "rgba(36, 252, 140, 0.10) 0%, rgba(36, 252, 140, 0) 50%",
        label: { bg: "#d3fee4", fg: "#218f5f" },
    },
    red: {
        border: "#F9B6BC",
        gradient: "rgba(252, 36, 126, 0.04) 0%, rgba(252, 36, 126, 0) 100%",
        gradientHover: "rgba(252, 36, 126, 0.10) 0%, rgba(252, 36, 126, 0) 50%",
        label: { bg: "#FCDADE", fg: "rgba(192, 58, 70, 1)" },
    },
    gray: {
        border: "#e9e9e9",
        gradient: "rgba(252, 36, 126, 0) 0%, rgba(252, 36, 126, 0) 100%",
        gradientHover: "rgba(252, 36, 126, 0.10) 0%, rgba(252, 36, 126, 0) 50%",
        label: { bg: "#e3e3e3", fg: "#7e7e7e" },
    },
}

export const USP = ({
    textFirst = true,
    color,
    label,
    title,
    preamble,
    illustration,
    preambleLast = false,
    vertical = false,
    ...rest
}: {
    textFirst?: boolean
    color: ColorSchemeUSPType
    label: string
    title: string
    preamble: string
    preambleLast?: boolean
    illustration: JSX.Element
    vertical?: boolean
}) => {
    const col = colorSchemeUSP[color]

    return (
        <div
            css={css`
                position: relative;
                overflow: hidden;
                border-radius: 16px;
                border: 1px solid ${col.border};
                background: linear-gradient(
                    ${textFirst ? "135deg" : "225deg"},
                    ${col.gradient}
                );
                ${lessThan(1)`
                            border-radius: 12px;
                `}
                ${lessThan(0)`
                            border-radius: 8px;
                `}
            `}
            {...rest}
        >
            <div
                css={css`
                    display: ${vertical ? "block" : "flex"};
                    ${!textFirst &&
                    css`
                        flex-direction: row-reverse;
                    `};
                    gap: 40px;
                    ${lessThan(1)`display:block;`}
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 1;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            text-wrap: balance;
                            padding: 40px 80px;
                            gap: 8px;
                            ${!vertical &&
                            !textFirst &&
                            css`
                                padding-left: 0;
                            `}
                            ${!vertical &&
                            textFirst &&
                            css`
                                padding-right: 0;
                            `}
                            ${!vertical &&
                            greaterThan(1)`
                                align-items: flex-start;
                                text-align: left;
                            `}
                            ${lessThan(1)`
                                padding: 40px 80px;
                            `}
                            ${lessThan(0)`padding: 20px 20px;`};
                        `}
                    >
                        {label && <Badge color={color}>{label}</Badge>}
                        <Title3>{title}</Title3>
                        {preambleLast && (
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex: 1;
                                `}
                            >
                                {illustration}
                            </div>
                        )}
                        <SubTitle>{preamble}</SubTitle>
                    </div>
                </div>
                {!preambleLast && (
                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 1;
                        `}
                    >
                        {illustration}
                    </div>
                )}
            </div>
        </div>
    )
}
