import { motion } from "framer-motion"
import { css } from "@shiftx/styles"
import { Title2Gradient, Title3 } from "../../components/Text"
import { BlockHeaderSpacer, BlockSpacer } from "../../components/Spacer"
import { SubTitle } from "../../components/Text"
import { USP } from "../../components/block/USP"
import { Section } from "../../components/Section"
import { Image } from "../../components/Image"
import { lessThan } from "../../utils/breakpoints"
import { DragAndDrop } from "../../components/visual/DragAndDrop"
import { NarrativeExample } from "../../components/visual/NarrativeExample"
import { MultipleUserCursors } from "../../components/visual/MultipleUserCursors"
import {
    ShortcutsLeft,
    ShortcutsRight,
} from "../../components/visual/Shortcuts"

export const TripletUSP = ({ content }) => {
    return (
        <div
            css={css`
                border-radius: 16px;
                --border-color: hsla(0deg 0% 100% / 0.12);
                border: 1px solid var(--border-color);
                background-color: hsla(0deg 0% 13% / 1);
            `}
        >
            <div
                css={css`
                    display: grid;
                    gap: 40px;
                    grid-template-columns: 1fr 400px 1fr;
                    grid-template-areas: "a b c";
                    padding: 80px;
                    ${lessThan(1)`
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr 1fr;
                            grid-template-areas: "b b" "a c";
                            padding: 40px;
                        `}
                `}
            >
                <ShortcutsLeft
                    css={css`
                        justify-self: flex-end;
                        width: 100%;
                    `}
                />
                <div
                    css={css`
                        grid-area: b;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        text-wrap: balance;
                        gap: 8px;
                        ${lessThan(1)`
                                text-align: center;
                                align-items: center;
                            `}
                    `}
                >
                    <Title3
                        css={css`
                            color: hsla(0deg 0% 100% / 0.87);
                        `}
                    >
                        {content.blocks[2].title}
                    </Title3>
                    <SubTitle
                        css={css`
                            color: hsla(0deg 0% 100% / 0.87);
                        `}
                    >
                        {content.blocks[2].subtitle}
                    </SubTitle>
                </div>
                <ShortcutsRight />
            </div>

            <div
                css={css`
                    border-top: 1px solid var(--border-color);
                    display: grid;
                `}
            >
                <div
                    css={css`
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        ${lessThan(1)`
                            grid-template-columns: 1fr`}
                    `}
                >
                    <div
                        css={css`
                            border-right: 1px solid var(--border-color);
                            padding: 80px;
                            gap: 40px;
                            display: flex;
                            flex-direction: column;

                            ${lessThan(1)`
                                    padding: 40px;
                                    border-right: none;
                                `}
                        `}
                    >
                        <NarrativeExample darkMode={true} />
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                                text-wrap: balance;
                                gap: 8px;
                            `}
                        >
                            <Title3
                                css={css`
                                    color: hsla(0deg 0% 100% / 0.87);
                                `}
                            >
                                {content.blocks[3].title}
                            </Title3>
                            <SubTitle
                                css={css`
                                    color: hsla(0deg 0% 100% / 0.87);
                                `}
                            >
                                {content.blocks[3].subtitle}
                            </SubTitle>
                        </div>
                    </div>

                    <div
                        css={css`
                            padding: 80px;
                            gap: 40px;
                            display: flex;
                            flex-direction: column;

                            ${lessThan(1)`
                                    border-top: 1px solid var(--border-color);
                                    padding: 40px;
                                `}
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}
                        >
                            <MultipleUserCursors darkmode={true} />
                        </div>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                                text-wrap: balance;
                                gap: 8px;
                            `}
                        >
                            <Title3
                                css={css`
                                    color: hsla(0deg 0% 100% / 0.87);
                                `}
                            >
                                {content.blocks[4].title}
                            </Title3>
                            <SubTitle
                                css={css`
                                    color: hsla(0deg 0% 100% / 0.87);
                                `}
                            >
                                {content.blocks[4].subtitle}
                            </SubTitle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ProcessMappingModelling = ({ content }) => {
    return (
        <Section>
            <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true, amount: 0.1 }}
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 8px;
                `}
            >
                <Title2Gradient>{content.title}</Title2Gradient>
                <SubTitle
                    css={css`
                        max-width: 540px;
                    `}
                >
                    {content.ingress}
                </SubTitle>
            </motion.div>
            <BlockHeaderSpacer />
            <div
                css={css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 40px;
                    ${lessThan(1)`
                        grid-template-columns: 1fr;
                        gap: 24px;
                    `}
                `}
            >
                <USP
                    textFirst={true}
                    vertical={true}
                    color="gray"
                    label={content.blocks[0].label}
                    title={content.blocks[0].title}
                    preamble={content.blocks[0].subtitle}
                    preambleLast={true}
                    illustration={
                        <Image
                            image={content.blocks[0].image[0]}
                            alt={content.blocks[0].image[0].alt}
                            css={css`
                                margin: 40px 0;
                            `}
                        />
                    }
                />
                <USP
                    textFirst={false}
                    vertical={true}
                    color="gray"
                    label={content.blocks[1].label}
                    title={content.blocks[1].title}
                    preamble={content.blocks[1].subtitle}
                    preambleLast={true}
                    illustration={
                        <DragAndDrop
                            css={css`
                                margin: 40px 0;
                            `}
                        />
                    }
                />
            </div>
            <BlockSpacer />
            <TripletUSP content={content} />
        </Section>
    )
}
