import { css } from "@shiftx/styles"

export const NarrativeExample = ({
    darkMode = false,
}: {
    darkMode?: boolean
}) => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: left;
                height: 120px;
                --color-high: hsla(0deg 0% 100% / 0.87);
                --color-medium: hsla(0deg 0% 100% / 0.6);
                --color-border: hsla(0deg 0% 100% / 0.12);
                --color-narrative-bg: hsla(0deg 0% 100% / 0.04);
                --color-text-subdued: rgba(255, 255, 255, 0.24);
                --color-yellow-subdued: rgba(255, 255, 255, 0.24);
                ${!darkMode &&
                css`
                    --color-high: var(--fg-high);
                    --color-medium: var(--fg-medium);
                    --color-border: var(--blue-500);
                    --color-narrative-bg: var(--bg-primary);
                    --color-text-subdued: var(--fg-400);
                    --color-yellow-subdued: var(--yellow-300);
                `}
                color: var(--color-high);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            `}
        >
            <div
                css={css`
                    display: block;
                `}
            >
                <div
                    css={css`
                        color: var(--color-medium);
                        margin-bottom: 4px;
                    `}
                >
                    Narrative
                </div>
                <div
                    css={css`
                        padding: 8px 16px;
                        border-radius: 8px;
                        border: 1px solid var(--color-border);
                        background-color: var(--color-narrative-bg);
                        width: 240px;
                    `}
                >
                    <span
                        css={css`
                            color: rgba(178, 97, 217, 1);
                            font-weight: 600;
                        `}
                    >
                        Customer
                    </span>{" "}
                    adds item to the{" "}
                    <span
                        css={css`
                            color: rgba(251, 183, 52, 1);
                            font-weight: 600;
                        `}
                    >
                        Shoppin
                    </span>
                    |
                    <span
                        css={css`
                            color: var(--color-yellow-subdued);
                            font-weight: 600;
                        `}
                    >
                        g cart
                    </span>{" "}
                    <span
                        css={css`
                            color: var(--color-text-subdued);
                        `}
                    >
                        and continues to checkout page
                    </span>
                </div>
            </div>
        </div>
    )
}
