import { css } from "@shiftx/styles"
import { motion } from "framer-motion"

export const MockMousePointer = ({
    strokeColor = "white",
    strokeWidth = 1.5,
    fillColor = "#212121",
    shadow = true,
    ...rest
}) => {
    return (
        <motion.div
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                ${shadow &&
                css`
                    svg {
                        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
                    }
                `}
            `}
            {...rest}
        >
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.41888 2.41885L22.3789 9.07219L14.0622 14.0622L9.07222 22.3789L2.41888 2.41885Z"
                    fill={fillColor}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                />
            </svg>
        </motion.div>
    )
}
