import { css } from "@shiftx/styles"
import { RequireAtLeastOne } from "../../types/RequireAtLeastOne"
import { lessThan } from "../utils/breakpoints"

type SpacerType = {
    width?: string
    height?: string
}

type HeightOrWidth = RequireAtLeastOne<SpacerType, "width" | "height">

export const Spacer = ({ width, height, ...rest }: HeightOrWidth) => {
    return (
        <div
            css={css`
                width: ${width ?? "auto"};
                height: ${height ?? "auto"};
            `}
            {...rest}
        ></div>
    )
}

export const SectionSpacer = ({ ...rest }) => {
    return (
        <Spacer
            height="120px"
            css={css`
                ${lessThan(1)`height: 120px;`}
                ${lessThan(0)`height: 80px;`}
            `}
            {...rest}
        />
    )
}

export const BlockHeaderSpacer = ({ ...rest }) => {
    return (
        <Spacer
            height="64px"
            css={css`
                ${lessThan(1)`height: 40px;`}
                ${lessThan(0)`height: 20px;`}
            `}
            {...rest}
        />
    )
}
export const BlockSpacer = ({ ...rest }) => {
    return (
        <Spacer
            height="40px"
            css={css`
                ${lessThan(1)`height: 24px;`}
            `}
            {...rest}
        />
    )
}