export const splitTitleIntoBlackAndBlue = (title, blueTitle) => {
    if (!blueTitle) return [{ color: "black", text: title }]

    if (title.includes(blueTitle)) {
        const indexStart = title.indexOf(blueTitle)
        const indexEnd = blueTitle.length
        const firstString = title.slice(0, indexStart)
        const secondString = title.slice(indexStart, indexStart + indexEnd)
        const thirdString = title.slice(indexStart + indexEnd)

        return [
            { color: "black", text: firstString },
            { color: "blue", text: secondString },
            { color: "black", text: thirdString },
        ]
    } else {
        return [{ color: "black", text: title }]
    }
}
