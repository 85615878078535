import { notationColors } from "@shiftx/data"

const IconRobot = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00326 0.664062C8.73963 0.664062 9.33659 1.26102 9.33659 1.9974C9.33659 2.49073 9.06992 2.92406 8.66992 3.15073V3.9974H9.33659C11.9139 3.9974 14.0033 6.08673 14.0033 8.66406H14.6699C15.0381 8.66406 15.3366 8.96254 15.3366 9.33073V11.3307C15.3366 11.6989 15.0381 11.9974 14.6699 11.9974H14.0033V12.6641C14.0033 13.4004 13.4063 13.9974 12.6699 13.9974H3.33659C2.60021 13.9974 2.00326 13.4004 2.00326 12.6641V11.9974H1.33659C0.968399 11.9974 0.669922 11.6989 0.669922 11.3307V9.33073C0.669922 8.96254 0.968399 8.66406 1.33659 8.66406H2.00326C2.00326 6.08673 4.09259 3.9974 6.66992 3.9974H7.33659V3.15073C6.93659 2.92406 6.66992 2.49073 6.66992 1.9974C6.66992 1.26102 7.26688 0.664062 8.00326 0.664062ZM5.00326 7.9974C4.08278 7.9974 3.33659 8.74359 3.33659 9.66406C3.33659 10.5845 4.08278 11.3307 5.00326 11.3307C5.92373 11.3307 6.66992 10.5845 6.66992 9.66406C6.66992 8.74359 5.92373 7.9974 5.00326 7.9974ZM9.33659 9.66406C9.33659 8.74359 10.0828 7.9974 11.0033 7.9974C11.9237 7.9974 12.6699 8.74359 12.6699 9.66406C12.6699 10.5845 11.9237 11.3307 11.0033 11.3307C10.0828 11.3307 9.33659 10.5845 9.33659 9.66406Z"
                fill="white"
            />
            <mask
                id="mask0_11249_31019"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="14"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.00326 0.664062C8.73963 0.664062 9.33659 1.26102 9.33659 1.9974C9.33659 2.49073 9.06992 2.92406 8.66992 3.15073V3.9974H9.33659C11.9139 3.9974 14.0033 6.08673 14.0033 8.66406H14.6699C15.0381 8.66406 15.3366 8.96254 15.3366 9.33073V11.3307C15.3366 11.6989 15.0381 11.9974 14.6699 11.9974H14.0033V12.6641C14.0033 13.4004 13.4063 13.9974 12.6699 13.9974H3.33659C2.60021 13.9974 2.00326 13.4004 2.00326 12.6641V11.9974H1.33659C0.968399 11.9974 0.669922 11.6989 0.669922 11.3307V9.33073C0.669922 8.96254 0.968399 8.66406 1.33659 8.66406H2.00326C2.00326 6.08673 4.09259 3.9974 6.66992 3.9974H7.33659V3.15073C6.93659 2.92406 6.66992 2.49073 6.66992 1.9974C6.66992 1.26102 7.26688 0.664062 8.00326 0.664062ZM5.00326 7.9974C4.08278 7.9974 3.33659 8.74359 3.33659 9.66406C3.33659 10.5845 4.08278 11.3307 5.00326 11.3307C5.92373 11.3307 6.66992 10.5845 6.66992 9.66406C6.66992 8.74359 5.92373 7.9974 5.00326 7.9974ZM9.33659 9.66406C9.33659 8.74359 10.0828 7.9974 11.0033 7.9974C11.9237 7.9974 12.6699 8.74359 12.6699 9.66406C12.6699 10.5845 11.9237 11.3307 11.0033 11.3307C10.0828 11.3307 9.33659 10.5845 9.33659 9.66406Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_11249_31019)">
                <rect width="16" height="16" fill="white" />
            </g>
        </svg>
    )
}

const IconServer = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66667 0.664062H13.3333C13.7015 0.664062 14 0.962539 14 1.33073V3.9974C14 4.36559 13.7015 4.66406 13.3333 4.66406H2.66667C2.29848 4.66406 2 4.36559 2 3.9974V1.33073C2 0.962539 2.29848 0.664062 2.66667 0.664062ZM2.66667 5.9974H13.3333C13.7015 5.9974 14 6.29587 14 6.66406V9.33073C14 9.69892 13.7015 9.9974 13.3333 9.9974H2.66667C2.29848 9.9974 2 9.69892 2 9.33073V6.66406C2 6.29587 2.29848 5.9974 2.66667 5.9974ZM13.3333 11.3307H2.66667C2.29848 11.3307 2 11.6292 2 11.9974V14.6641C2 15.0323 2.29848 15.3307 2.66667 15.3307H13.3333C13.7015 15.3307 14 15.0323 14 14.6641V11.9974C14 11.6292 13.7015 11.3307 13.3333 11.3307ZM6 3.33073H6.66667V1.9974H6V3.33073ZM6.66667 8.66406H6V7.33073H6.66667V8.66406ZM6 13.9974H6.66667V12.6641H6V13.9974ZM3.33333 3.33073V1.9974H4.66667V3.33073H3.33333ZM3.33333 7.33073V8.66406H4.66667V7.33073H3.33333ZM3.33333 13.9974V12.6641H4.66667V13.9974H3.33333Z"
                fill="white"
            />
            <mask
                id="mask0_11249_31020"
                maskUnits="userSpaceOnUse"
                x="2"
                y="0"
                width="12"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.66667 0.664062H13.3333C13.7015 0.664062 14 0.962539 14 1.33073V3.9974C14 4.36559 13.7015 4.66406 13.3333 4.66406H2.66667C2.29848 4.66406 2 4.36559 2 3.9974V1.33073C2 0.962539 2.29848 0.664062 2.66667 0.664062ZM2.66667 5.9974H13.3333C13.7015 5.9974 14 6.29587 14 6.66406V9.33073C14 9.69892 13.7015 9.9974 13.3333 9.9974H2.66667C2.29848 9.9974 2 9.69892 2 9.33073V6.66406C2 6.29587 2.29848 5.9974 2.66667 5.9974ZM13.3333 11.3307H2.66667C2.29848 11.3307 2 11.6292 2 11.9974V14.6641C2 15.0323 2.29848 15.3307 2.66667 15.3307H13.3333C13.7015 15.3307 14 15.0323 14 14.6641V11.9974C14 11.6292 13.7015 11.3307 13.3333 11.3307ZM6 3.33073H6.66667V1.9974H6V3.33073ZM6.66667 8.66406H6V7.33073H6.66667V8.66406ZM6 13.9974H6.66667V12.6641H6V13.9974ZM3.33333 3.33073V1.9974H4.66667V3.33073H3.33333ZM3.33333 7.33073V8.66406H4.66667V7.33073H3.33333ZM3.33333 13.9974V12.6641H4.66667V13.9974H3.33333Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_11249_31020)">
                <rect width="16" height="16" fill="white" />
            </g>
        </svg>
    )
}

const IconUser = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            id="fas-user"
            {...props}
        >
            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"></path>
        </svg>
    )
}
const IconUsers = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            id="fas-users"
            {...props}
        >
            <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"></path>
        </svg>
    )
}

const IconShiftx = ({ ...props }) => {
    return (
        <svg
            viewBox="0 0 16 16"
            id="logo-shiftx-solid"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1 1H5.2C5.2 6.6 15 6.6 15 15H10.8C10.8 9.4 1 9.4 1 1Z" />
            <path d="M13.6423 5.69486C14.4638 4.47005 15 2.96482 15 1H10.8C10.8 1.9075 10.5426 2.66793 10.1113 3.34089L13.6423 5.69486Z" />
            <path d="M1 15H5.2C5.2 14.0925 5.45736 13.3321 5.88867 12.6591L2.35771 10.3052C1.53618 11.53 1 13.0352 1 15Z" />
        </svg>
    )
}
const IconLandmark = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            id="fas-landmark"
            {...props}
        >
            <path d="M240.1 4.2c9.8-5.6 21.9-5.6 31.8 0l171.8 98.1L448 104l0 .9 47.9 27.4c12.6 7.2 18.8 22 15.1 36s-16.4 23.8-30.9 23.8H32c-14.5 0-27.2-9.8-30.9-23.8s2.5-28.8 15.1-36L64 104.9V104l4.4-1.6L240.1 4.2zM64 224h64V416h40V224h64V416h48V224h64V416h40V224h64V420.3c.6 .3 1.2 .7 1.8 1.1l48 32c11.7 7.8 17 22.4 12.9 35.9S494.1 512 480 512H32c-14.1 0-26.5-9.2-30.6-22.7s1.1-28.1 12.9-35.9l48-32c.6-.4 1.2-.7 1.8-1.1V224z"></path>
        </svg>
    )
}

export const mockActors = [
    {
        id: "robot",
        name: "Robot",
        color: notationColors.yellow.default,
        comp: <IconRobot />,
    },
    {
        id: "server",
        name: "Server",
        color: notationColors.purple.default,
        comp: <IconServer />,
    },
    {
        id: "online-portal",
        name: "Online Portal",
        color: notationColors.yellow.default,
        comp: <IconServer />,
    },
    {
        id: "customer",
        name: "Customer",
        color: notationColors.purple.default,
        comp: <IconUser width={16} height={16} fill="white" />,
    },
    {
        id: "credit-agency",
        name: "Credit Agency",
        color: notationColors.purple.default,
        comp: <IconServer />,
    },
    {
        id: "sales",
        name: "Sales",
        color: notationColors.blue.default,
        comp: <IconUsers width={16} height={16} fill="white" />,
    },
    {
        id: "stripe",
        name: "Stripe",
        color: notationColors.yellow.default,
        comp: <IconUsers width={16} height={16} fill="white" />,
    },
    {
        id: "shiftx",
        name: "ShiftX",
        color: notationColors.yellow.default,
        comp: <IconShiftx width={16} height={16} fill="white" />,
    },
    {
        id: "population-register",
        name: "Population Register",
        color: notationColors.brown.default,
        comp: <IconLandmark width={16} height={16} fill="white" />,
    },
    {
        id: "debtor",
        name: "Debtor",
        color: notationColors.turquoise.default,
        comp: <IconUser width={16} height={16} fill="white" />,
    },
]
