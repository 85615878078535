import { css } from "@shiftx/styles"

const Comment = ({
    name,
    created,
    children,
}: {
    name: string
    created: string
    children: any
}) => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                padding: 24px;
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                font-family: "Inter";
                gap: 16px;
                border-bottom: 1px solid var(--fg-200);
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 16px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--bg-primary);
                        background-color: black;
                        border-radius: 50%;
                        width: 28px;
                        height: 28px;
                    `}
                >
                    {name[0]}
                </div>
                <div>
                    <div
                        css={css`
                            font-family: "Albert Sans";
                            font-size: 14px;
                        `}
                    >
                        {name}
                    </div>
                    <div
                        css={css`
                            color: var(--fg-low);
                            font-size: 12px;
                        `}
                    >
                        {created}
                    </div>
                </div>
            </div>
            <div
                css={css`
                    color: var(--fg-medium);
                `}
            >
                {children}
            </div>
        </div>
    )
}

export const MockCommentReply = ({ scale, ...rest }) => {
    return (
        <div
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(${scale});
            `}
        >
            <div
                css={theme => css`
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: "Inter";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 320px;
                    display: flex;
                    flex-direction: column;
                    user-select: none;
                    border-radius: 8px;
                    padding: 24px;
                    gap: 16px;
                    background-color: var(--bg-primary);
                    box-shadow: 0 0 25px 0px var(--fg-300);
                `}
                {...rest}
            >
                <div>Perfect! Once we have all the facts, we can make |</div>
                <div
                    css={css`
                        width: fit-content;
                        padding: 6px 12px;
                        border-radius: 4px;
                        color: var(--blue-500);
                        border: 1px solid var(--blue-500);
                        line-height: 24px;
                    `}
                >
                    Reply
                </div>
            </div>
        </div>
    )
}

export const MockComment = ({ scale, ...rest }) => {
    return (
        <div
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(${scale});
            `}
        >
            <div
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 400px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    user-select: none;
                    border-radius: 8px;
                    background-color: var(--bg-primary);
                    border: 1px solid var(--fg-200);
                    box-shadow: 0 0 20px var(--fg-200);
                `}
                {...rest}
            >
                <Comment name="James Wilson" created="6 hours ago">
                    Hey, have you looked into the new financial system upgrade{" "}
                    <span
                        css={css`
                            color: var(--blue-500);
                        `}
                    >
                        @Silvia
                    </span>
                    ? It's supposed to streamline our transaction processing.
                </Comment>
                <Comment name="Silvia Johnson" created="30 min ago">
                    <span
                        css={css`
                            color: var(--blue-500);
                        `}
                    >
                        @James
                    </span>{" "}
                    Yeah, I've started digging into it. The documentation seems
                    comprehensive, but I'm wondering how it handles
                    reconciliation for complex derivatives. As our client base
                    grows, we need to ensure the system can handle the increased
                    load without compromising speed and accuracy.
                </Comment>
            </div>
        </div>
    )
}
