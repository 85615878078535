import { css } from "@shiftx/styles"
import { motion } from "framer-motion"
import { Section } from "../../components/Section"
import { BlockHeaderSpacer } from "../../components/Spacer"
import { SubTitle, Title2Gradient } from "../../components/Text"
import { lessThan } from "../../utils/breakpoints"
import { USP } from "../../components/block/USP"
import {
    MockShareModal,
    MockUsersPopover,
} from "../../components/Mock/MockShareModal"
import { useRef } from "react"
import {
    MockComment,
    MockCommentReply,
} from "../../components/Mock/MockCommentModal"
import {
    MockIssueModal,
    MockIssuePopover,
} from "../../components/Mock/MockIssue"
import { useRefResize } from "../../hooks/useRefResize"
import { splitTitleIntoBlackAndBlue } from "../../utils/title"

const ShareGUI = () => {
    const ref = useRef(null)
    const container = useRefResize(ref)
    return (
        <div
            ref={ref}
            css={css`
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                max-width: 540px;
                height: auto;
            `}
        >
            <div
                css={css`
                    position: relative;
                    aspect-ratio: 720/380;
                `}
            />
            <MockShareModal
                scale={container.w / 720}
                css={css`
                    transform: translate(160px, 24px);
                `}
            />
            <MockUsersPopover
                scale={container.w / 720}
                css={css`
                    transform: translate(120px, 94px);
                `}
            />
        </div>
    )
}

export const IssueUSP = () => {
    const ref = useRef(null)
    const container = useRefResize(ref)
    return (
        <div
            ref={ref}
            css={css`
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                max-width: 540px;
                height: auto;
            `}
        >
            <div
                css={css`
                    position: relative;
                    aspect-ratio: 720/380;
                `}
            />
            <MockIssueModal
                scale={container.w / 720}
                css={css`
                    transform: translate(160px, 24px);
                `}
            />
            <MockIssuePopover
                scale={container.w / 720}
                css={css`
                    transform: translate(400px, 63px);
                `}
            />
        </div>
    )
}

export const CommentGUI = () => {
    const ref = useRef(null)
    const container = useRefResize(ref)
    return (
        <div
            ref={ref}
            css={css`
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                max-width: 540px;
                height: auto;
            `}
        >
            <div
                css={css`
                    position: relative;
                    aspect-ratio: 720/380;
                `}
            />
            <MockComment
                scale={container.w / 720}
                css={css`
                    transform: translate(160px, 24px);
                `}
            />
            <MockCommentReply
                scale={container.w / 720}
                css={css`
                    transform: translate(280px, 160px);
                `}
            />
        </div>
    )
}

export const Collaborative = ({ content }) => {
    const titleArray = splitTitleIntoBlackAndBlue(content.title, content.blue)
    return (
        <Section>
            <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true, amount: 0.1 }}
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 16px;
                `}
            >
                <Title2Gradient>
                    {titleArray.map((item, index) => {
                        return item.color === "black" ? (
                            <span key={index}>{item.text}</span>
                        ) : (
                            <span
                                key={index}
                                css={css`
                                    color: var(--brand-base);
                                `}
                            >
                                {item.text}
                            </span>
                        )
                    })}
                </Title2Gradient>
                <SubTitle
                    css={css`
                        max-width: 540px;
                    `}
                >
                    {content.tagline}
                </SubTitle>
            </motion.div>
            <BlockHeaderSpacer />
            <div
                css={css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 24px;
                    ${lessThan(1)`
                        display: flex;
                        flex-direction: column;
                    `}
                    ${lessThan(0)`
                        gap: 12px;
                    `}
                `}
            >
                <USP
                    textFirst={true}
                    color="yellow"
                    label={content.blocks[0].label}
                    title={content.blocks[0].title}
                    preamble={content.blocks[0].description}
                    illustration={<ShareGUI />}
                    vertical={true}
                />
                <USP
                    textFirst={true}
                    color="green"
                    label={content.blocks[1].label}
                    title={content.blocks[1].title}
                    preamble={content.blocks[1].description}
                    illustration={<CommentGUI />}
                    vertical={true}
                />
            </div>
        </Section>
    )
}
