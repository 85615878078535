import { css } from "@shiftx/styles"
import { motion } from "framer-motion"
import { greaterThan, lessThan } from "../../utils/breakpoints"
import { Label, SubTitle, Title2Gradient, Title3 } from "../../components/Text"
import { Section } from "../../components/Section"
import { BlockHeaderSpacer, BlockSpacer } from "../../components/Spacer"
import { CommentGUI, IssueUSP } from "../HomePage/Collaborative"
import { TimeAnimation } from "../../components/visual/TimeAnimation"
import { Chat, Flag } from "@shiftx/icons"
import { Image } from "../../components/Image"

const colorScheme = {
    purple: {
        border: "#eedbf6",
        gradient: "rgba(168, 75, 212, 0.04) 0%, rgba(168, 75, 212, 0) 100%",
        gradientHover: "rgba(168, 75, 212, 0.10) 0%, rgba(168, 75, 212, 0) 50%",
        label: { bg: "#eedbf6;", fg: "#863caa" },
    },
    orange: {
        border: "#fee3d3",
        gradient: "rgba(252, 113, 36, 0.04) 0%, rgba(252, 113, 36, 0) 100%",
        gradientHover: "rgba(252, 113, 36, 0.10) 0%, rgba(252, 113, 36, 0) 50%",
        label: { bg: "#fee3d3", fg: "#ca5a1d" },
    },
    yellow: {
        border: "#FDDEA3",
        gradient: "rgba(250, 173, 24, 0.04) 0%, rgba(254, 239, 209, 0) 100%",
        gradientHover:
            "rgba(250, 173, 24, 0.10) 0%, rgba(254, 239, 209, 0) 50%",
        label: { bg: "rgba(254, 239, 209, 1)", fg: "rgba(200, 138, 19, 1)" },
    },
    blue: {
        border: "#b8cfed",
        gradient: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        gradientHover: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        iconBg: "#ECF2FD",
        label: { bg: "transparent", fg: "rgba(70, 126, 235, 1)" },
    },
    green: {
        border: "#ACE0C1",
        gradient: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        gradientHover: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        label: { bg: "transparent", fg: "#218f5f" },
    },
    red: {
        border: "#F9B6BC",
        gradient: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        gradientHover: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        iconBg: "#FEEDEE",
        label: { bg: "transparent", fg: "rgba(240, 72, 88, 1)" },
    },
    gray: {
        border: "#E0E0E0",
        gradient: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        gradientHover: "var(--bg-primary) 0%, rgba(255, 255, 255, 0.00) 100%",
        label: { bg: "transparent", fg: "#7e7e7e" },
    },
}

export const ImproveUSP = ({
    textFirst = true,
    color,
    label,
    icon,
    title,
    preamble,
    illustration,
    vertical = false,
}: {
    textFirst?: boolean
    color: "purple" | "yellow" | "blue" | "green" | "red" | "orange" | "gray"
    label: string
    title: string
    icon?: JSX.Element
    preamble: string
    illustration: JSX.Element
    vertical?: boolean
}) => {
    const col = colorScheme[color]

    return (
        <div
            css={css`
                position: relative;
                overflow: hidden;
                border-radius: 16px;
                background: linear-gradient(
                    ${textFirst ? "180deg" : "225deg"},
                    ${col.gradient}
                );
                ${lessThan(1)`
                    border-radius: 12px;
                `}
                ${lessThan(0)`
                    border-radius: 8px;
                `}

                :before {
                    content: "";
                    position: absolute;
                    inset: 0;
                    border-radius: inherit;
                    padding: 1px;
                    background: linear-gradient(
                        180deg,
                        ${col.border},
                        var(--fg-200)
                    );
                    mask:
                        linear-gradient(#fff 0 0) content-box,
                        linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            `}
        >
            <div
                css={css`
                    display: ${vertical ? "block" : "flex"};
                    gap: 40px;
                    ${!textFirst &&
                    css`
                        flex-direction: row-reverse;
                    `};
                    ${lessThan(1)`display:block; `}
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 1;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            text-wrap: balance;
                            padding: 40px 80px;
                            ${!vertical &&
                            !textFirst &&
                            css`
                                padding-left: 0;
                            `}
                            ${!vertical &&
                            textFirst &&
                            css`
                                padding-right: 0;
                            `}
                            ${!vertical &&
                            greaterThan(1)`
                                align-items: flex-start;
                                text-align: left;
                            `}
                            ${lessThan(1)`
                                padding: 40px 80px;
                            `}
                            ${lessThan(0)`padding: 20px 20px;`};
                        `}
                    >
                        {icon && (
                            <div
                                css={css`
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 100%;
                                    background-color: ${col.iconBg};
                                    position: relative;
                                    margin: auto;
                                `}
                            >
                                <div
                                    css={css`
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        top: 10px;
                                        left: 10px;
                                    `}
                                >
                                    {icon}
                                </div>
                            </div>
                        )}

                        {label && (
                            <Label
                                css={css`
                                    width: fit-content;
                                    padding: 2px 8px;
                                    background-color: ${col.label.bg};
                                    border-radius: 4px;
                                    color: ${col.label.fg};
                                `}
                            >
                                {label}
                            </Label>
                        )}

                        <div
                            css={css`
                                font-family: "Albert Sans";
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 36px;
                                color: var(--fg-high);
                                margin: 0;
                                ${lessThan(0)`
                                    font-size: 16px;
                                    line-height: 28px;
                                `}
                            `}
                        >
                            {title}
                        </div>
                        <SubTitle
                            css={css`
                                padding-top: 8px;
                            `}
                        >
                            {preamble}
                        </SubTitle>
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 1;
                    `}
                >
                    {illustration}
                </div>
            </div>
        </div>
    )
}

export const ProcessMappingImprove = ({ content }) => {
    return (
        <div
            css={css`
                background: #f5f5f5;
                padding: 120px 0;
                ${lessThan(1)`padding: 80px 0;`}
                ${lessThan(0)`padding: 60px 0;`}
            `}
        >
            <Section>
                <motion.div
                    initial={{ opacity: 0, translateY: 40 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true, amount: 0.1 }}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        align-items: center;
                        gap: 8px;
                    `}
                >
                    <Title2Gradient>{content.title}</Title2Gradient>
                    <SubTitle
                        css={css`
                            max-width: 540px;
                        `}
                    >
                        {content.ingress}
                    </SubTitle>
                </motion.div>
                <BlockHeaderSpacer />
                <div
                    css={css`
                        position: relative;
                        overflow: hidden;
                        border-radius: 16px;
                        border: 1px solid var(--fg-200);
                        background: linear-gradient(
                            180deg,
                            #fff 0%,
                            rgba(255, 255, 255, 0) 100%
                        );
                        color: var(--fg-high);
                        ${lessThan(1)`border-radius: 12px;`}
                        ${lessThan(0)`border-radius: 8px;`}
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                            ${lessThan(1)`
                                flex-direction: column;
                            `}
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                flex: 3;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    text-align: center;
                                    text-wrap: balance;
                                    padding: 160px 80px;
                                    gap: 8px;
                                    ${greaterThan(1)`
                                        align-items: flex-start;
                                        text-align: left;
                                    `}
                                    ${lessThan(1)`padding: 40px 80px;`}
                                    ${lessThan(0)`padding: 20px 20px;`};
                                `}
                            >
                                <Title3>{content.blocks[0].title}</Title3>
                                <SubTitle>
                                    {content.blocks[0].subtitle}
                                </SubTitle>
                            </div>
                        </div>
                        <div
                            css={css`
                                position: relative;
                                flex: 4;
                                overflow: visible;
                                ${lessThan(1)`
                                    flex: 1 1 300px;
                                `}
                            `}
                        >
                            <Image
                                image={content.blocks[0].image[0]}
                                alt={content.blocks[0].image[0].alt}
                                css={css`
                                    position: absolute;
                                    width: 1000px;
                                    height: auto;
                                    top: 40px;
                                    left: 0px;
                                    border-radius: 8px;
                                    box-shadow: 0px 0px 120px 0px var(--fg-300);
                                    ${lessThan(1)`
                                        margin-left: 40px;
                                        top: 0px;
                                        right: 40px;
                                    `}
                                `}
                            />
                        </div>
                    </div>
                </div>
                <BlockSpacer />
                <div
                    css={css`
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        flex-direction: column;
                        gap: 40px;
                        ${lessThan(1)`
                            display: flex;
                            gap: 24px;
                        `}
                    `}
                >
                    <ImproveUSP
                        textFirst={true}
                        color="red"
                        label={content.blocks[1].label}
                        title={content.blocks[1].title}
                        icon={
                            <Flag
                                width={20}
                                height={20}
                                css={css`
                                    color: var(--red-500);
                                `}
                            />
                        }
                        preamble={content.blocks[1].subtitle}
                        vertical={true}
                        illustration={<IssueUSP />}
                    />
                    <ImproveUSP
                        textFirst={false}
                        color="blue"
                        label={content.blocks[2].label}
                        title={content.blocks[2].title}
                        icon={
                            <Chat
                                width={20}
                                height={20}
                                css={css`
                                    color: var(--blue-500);
                                `}
                            />
                        }
                        preamble={content.blocks[2].subtitle}
                        vertical={true}
                        illustration={<CommentGUI />}
                    />
                </div>
                <BlockSpacer />
                <ImproveUSP
                    textFirst={false}
                    color="green"
                    label={content.blocks[3].label}
                    title={content.blocks[3].title}
                    preamble={content.blocks[3].subtitle}
                    illustration={<TimeAnimation />}
                />
            </Section>
        </div>
    )
}
