import { css } from "@shiftx/styles"
import { lessThan } from "../../utils/breakpoints"

const ShortcutButton = ({ wide = false, children }) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                width: ${wide ? "80px" : "40px"};
                height: 40px;
                border-radius: 4px;
                border: 1px solid hsla(0deg 0% 100% / 0.12);
                background-color: hsla(0deg 0% 100% / 0.04);
                color: hsla(0deg 0% 100% / 0.87);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                ${lessThan(0)`
                    ${
                        wide &&
                        css`
                            width: 50px;
                            height: 30px;
                        `
                    }
                    ${
                        !wide &&
                        css`
                            width: 30px;
                            height: 30px;
                        `
                    }
                `}
            `}
        >
            {children}
        </div>
    )
}

export const ShortcutsLeft = ({ ...rest }) => {
    return (
        <div
            css={css`
                grid-area: a;
                display: flex;
                flex-direction: column;
                gap: 16px;
                max-width: 208px;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                --gap-siblings: 8px;
                ${lessThan(0)`
                    --gap-siblings: 4px;
                    
                `}
            `}
            {...rest}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    gap: var(--gap-siblings);
                `}
            >
                <ShortcutButton wide={true}>⇧</ShortcutButton>
                <ShortcutButton>X</ShortcutButton>
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    gap: var(--gap-siblings);
                `}
            >
                <ShortcutButton wide={true}>⌘</ShortcutButton>
                <ShortcutButton>Y</ShortcutButton>
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    gap: var(--gap-siblings);
                `}
            >
                <ShortcutButton wide={true}>⌥</ShortcutButton>
                <ShortcutButton>Z</ShortcutButton>
            </div>
        </div>
    )
}

export const ShortcutsRight = ({ ...rest }) => {
    return (
        <div
            css={css`
                grid-area: c;
                display: flex;
                flex-direction: column;
                gap: 16px;
                max-width: 208px;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                --gap-siblings: 8px;
                ${lessThan(0)`
                    --gap-siblings: 4px;
                `}
            `}
            {...rest}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    gap: var(--gap-siblings);
                `}
            >
                <ShortcutButton wide={true}>⇧</ShortcutButton>
                <ShortcutButton>A</ShortcutButton>
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    gap: var(--gap-siblings);
                `}
            >
                <ShortcutButton wide={true}>⌘</ShortcutButton>
                <ShortcutButton>B</ShortcutButton>
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    gap: var(--gap-siblings);
                `}
            >
                <ShortcutButton wide={true}>⌥</ShortcutButton>
                <ShortcutButton>C</ShortcutButton>
            </div>
        </div>
    )
}
