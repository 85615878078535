export const notationColors = {
    red: {
        lightest: "#F4BDB5",
        lighter: "#F9DEDA",
        default: "#DC3018",
        darker: "#B92814",
        darkest: "#962110",
    },
    yellow: {
        lighter: "#FEEDDA",
        lightest: "#FDDAB5",
        default: "#F88C18",
        darker: "#D07614",
        darkest: "#A95F10",
    },
    green: {
        lighter: "#E3F0DD",
        lightest: "#C7E2BA",
        default: "#50A428",
        darker: "#438A22",
        darkest: "#36701B",
    },
    turquoise: {
        lighter: "#DAF0F3",
        lightest: "#B5E2E7",
        default: "#18A4B4",
        darker: "#148A97",
        darkest: "#10707A",
    },
    blue: {
        lighter: "#DAE3F6",
        lightest: "#B5C7ED",
        default: "#1850C8",
        darker: "#1443A8",
        darkest: "#103688",
    },
    purple: {
        lighter: "#E6DDF0",
        lightest: "#CCBAE2",
        default: "#6028A4",
        darker: "#51228A",
        darkest: "#411B70",
    },
    pink: {
        lighter: "#F6DDF0",
        lightest: "#EDBAE2",
        default: "#C828A4",
        darker: "#A8228A",
        darkest: "#881B70",
    },
    gray: {
        default: "#787878",
    },
    brown: {
        default: "#604030",
    },
}
