import { ChevronFilledRight, InProgress, X } from "@shiftx/icons"
import { TextStyle, css, getThemeProp } from "@shiftx/styles"

const IconTeams = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
        >
            <g clipPath="url(#clip0_568_39814)">
                <path
                    d="M11.1634 5.5791H15.2963C15.6867 5.5791 16.0033 5.89563 16.0033 6.28609V10.0506C16.0033 11.4857 14.8399 12.649 13.4049 12.649H13.3926C11.9576 12.6492 10.7942 11.486 10.7939 10.051C10.7939 10.0509 10.7939 10.0507 10.7939 10.0506V5.9486C10.794 5.74453 10.9594 5.5791 11.1634 5.5791Z"
                    fill="#5059C9"
                />
                <path
                    d="M13.9547 4.8362C14.8794 4.8362 15.6291 4.08652 15.6291 3.16175C15.6291 2.23698 14.8794 1.4873 13.9547 1.4873C13.0299 1.4873 12.2803 2.23698 12.2803 3.16175C12.2803 4.08652 13.0299 4.8362 13.9547 4.8362Z"
                    fill="#5059C9"
                />
                <path
                    d="M8.74283 4.83535C10.0786 4.83535 11.1614 3.75248 11.1614 2.4167C11.1614 1.08091 10.0786 -0.00195312 8.74283 -0.00195312C7.40707 -0.00195312 6.32422 1.08091 6.32422 2.4167C6.32422 3.75248 7.40707 4.83535 8.74283 4.83535Z"
                    fill="#7B83EB"
                />
                <path
                    d="M11.9729 5.5791H5.15098C4.76517 5.58865 4.45998 5.90883 4.46893 6.29465V10.5883C4.41506 12.9036 6.24677 14.8249 8.56196 14.8816C10.8771 14.8249 12.7089 12.9036 12.655 10.5883V6.29465C12.6639 5.90883 12.3587 5.58865 11.9729 5.5791Z"
                    fill="#7B83EB"
                />
                <path
                    opacity="0.1"
                    d="M8.93309 5.5791V11.596C8.93124 11.8719 8.76404 12.1197 8.5089 12.2248C8.42767 12.2592 8.34036 12.2769 8.25216 12.2769H4.79541C4.74704 12.1541 4.70239 12.0313 4.66518 11.9048C4.53493 11.4778 4.46847 11.034 4.46797 10.5876V6.29353C4.45902 5.90833 4.76371 5.58865 5.1489 5.5791H8.93309Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M8.561 5.5791V11.9681C8.56098 12.0563 8.54327 12.1436 8.5089 12.2248C8.40385 12.4799 8.15598 12.6471 7.88007 12.649H4.9703C4.90704 12.5262 4.84751 12.4034 4.79541 12.2769C4.74332 12.1504 4.70239 12.0313 4.66518 11.9048C4.53493 11.4778 4.46847 11.034 4.46797 10.5876V6.29353C4.45902 5.90833 4.76371 5.58865 5.1489 5.5791H8.561Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M8.56099 5.5791V11.2239C8.55816 11.5988 8.25495 11.902 7.88006 11.9048H4.66518C4.53493 11.4778 4.46847 11.034 4.46797 10.5876V6.29353C4.45902 5.90833 4.76371 5.58865 5.1489 5.5791H8.56099Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M8.18889 5.5791V11.2239C8.18606 11.5988 7.88285 11.902 7.50796 11.9048H4.66518C4.53493 11.4778 4.46847 11.034 4.46797 10.5876V6.29353C4.45902 5.90833 4.76371 5.58865 5.1489 5.5791H8.18889Z"
                    fill="black"
                />
                <path
                    opacity="0.1"
                    d="M8.92928 3.65457V4.82668C8.86602 4.8304 8.80649 4.83413 8.74323 4.83413C8.67997 4.83413 8.62044 4.83041 8.55718 4.82668C8.43159 4.81835 8.30702 4.79842 8.18509 4.76715C7.4316 4.58871 6.8091 4.06026 6.51067 3.34573C6.45933 3.22574 6.41946 3.10114 6.3916 2.97363H8.24834C8.62382 2.97505 8.92785 3.27908 8.92928 3.65457Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M8.5614 4.02761V4.82763C8.4358 4.8193 8.31124 4.79937 8.18931 4.7681C7.43582 4.58966 6.81332 4.06121 6.51489 3.34668H7.88047C8.25594 3.3481 8.55997 3.65214 8.5614 4.02761Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M8.5614 4.02761V4.82763C8.4358 4.8193 8.31124 4.79937 8.18931 4.7681C7.43582 4.58966 6.81332 4.06121 6.51489 3.34668H7.88047C8.25594 3.3481 8.55997 3.65214 8.5614 4.02761Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M8.18931 4.02762V4.7681C7.43582 4.58966 6.81332 4.06121 6.51489 3.34668H7.50838C7.88386 3.34811 8.18788 3.65214 8.18931 4.02762Z"
                    fill="black"
                />
                <path
                    d="M0.685705 3.34668H7.50766C7.88434 3.34668 8.1897 3.65205 8.1897 4.02873V10.8508C8.1897 11.2275 7.88434 11.5329 7.50766 11.5329H0.685705C0.30902 11.5329 0.00366211 11.2275 0.00366211 10.8508V4.02873C0.00366211 3.65205 0.309027 3.34668 0.685705 3.34668Z"
                    fill="url(#paint0_linear_568_39814)"
                />
                <path
                    d="M5.88732 5.94304H4.52359V9.6566H3.65476V5.94304H2.29736V5.22266H5.88732V5.94304Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_568_39814"
                    x1="1.42574"
                    y1="2.81373"
                    x2="6.76777"
                    y2="12.0657"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5A62C3" />
                    <stop offset="0.5" stopColor="#4D55BD" />
                    <stop offset="1" stopColor="#3940AB" />
                </linearGradient>
                <clipPath id="clip0_568_39814">
                    <rect width="16" height="14.884" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const User = ({
    children,
    inline,
    selected = false,
}: {
    children: any
    selected?: boolean
    inline?: boolean
}) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 42px;
                ${inline &&
                `
                    height: 32px;
                `}
                padding: 8px 12px;
                gap: 8px;
                ${selected &&
                `
                    background-color: var(--fg-100);
                `}
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background-color: black;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                `}
            >
                {children[0]}
            </div>
            {children}
        </div>
    )
}

export const MockIssuePopover = ({ scale, ...rest }) => {
    return (
        <div
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(${scale});
            `}
        >
            <div
                css={theme => css`
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: "Inter";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 240px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    user-select: none;
                    border-radius: 8px;
                    background-color: var(--bg-primary);
                    box-shadow: 0 0 25px 0px var(--fg-300);
                `}
                {...rest}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 400;
                        font-family: "Inter";
                        padding: 8px 12px;
                        height: 42px;
                        width: 100%;
                        color: var(--fg-low);
                        border-bottom: 1px solid var(--fg-300);
                    `}
                >
                    Search ...
                </div>
                <User>John Jameson</User>
                <User selected={true}>Marvin McKinney</User>
                <User>Jenny Wilson</User>
                <User>Theresa Webb</User>
            </div>
        </div>
    )
}

export const MockIssueModal = ({ scale, ...rest }) => {
    return (
        <div
            css={css`
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(${scale});
            `}
        >
            <div
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 400px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    user-select: none;
                    border-radius: 8px;
                    background-color: var(--bg-primary);
                    border: 1px solid var(--fg-200);
                    box-shadow: 0 0 20px var(--fg-200);
                `}
                {...rest}
            >
                <div
                    css={theme => css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0px 24px;
                        width: 100%;
                        height: 48px;
                        border-bottom: 1px solid var(--fg-200);
                        ${getThemeProp(theme, TextStyle.TextMedium)};
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            gap: 16px;
                        `}
                    >
                        <div
                            css={css`
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 48px;
                                color: var(--fg-high);
                            `}
                        >
                            Issue
                        </div>
                    </div>
                    <X
                        width={16}
                        height={16}
                        css={css`
                            padding: 8px;
                        `}
                    />
                </div>
                <div
                    css={css`
                        font-family: "Inter";
                        font-size: 13px;
                        display: flex;
                        width: 100%;
                        padding: 24px;
                        flex-direction: column;
                    `}
                >
                    <div
                        css={css`
                            color: var(--fg-high);
                            font-weight: 600;
                            font-size: 20px;
                            font-family: "Albert Sans";
                            line-height: 36px;
                        `}
                    >
                        Add new shipping option
                    </div>
                    <div
                        css={css`
                            margin-bottom: 18px;
                            color: var(--fg-medium);
                        `}
                    >
                        <span
                            css={css`
                                color: var(--blue-500);
                            `}
                        >
                            @John
                        </span>
                        , can you add the new shipping option to the dropdown
                        menu?
                    </div>

                    <div
                        css={css`
                            display: flex;
                            gap: 8px;
                            margin-bottom: 10px;
                        `}
                    >
                        <div
                            css={css`
                                padding: 6px 12px;
                                border-radius: 4px;
                                line-height: 24px;
                                border: 1px solid var(--fg-300);
                            `}
                        >
                            Edit
                        </div>
                        <div
                            css={css`
                                padding: 6px 12px;
                                border-radius: 4px;
                                line-height: 24px;
                                border: 1px solid var(--fg-300);
                            `}
                        >
                            ...
                        </div>
                    </div>
                    <div
                        css={css`
                            border-bottom: 1px solid var(--fg-300);
                            margin: 10px 0 10px -23px;
                        `}
                    />

                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-top: 12px;
                            color: var(--fg-medium);
                        `}
                    >
                        <div>Assign to</div>
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 200px;
                                height: 36px;
                                border-radius: 4px;
                                border: 1px solid var(--fg-300);
                                color: var(--fg-high);
                                line-height: 34px;
                                padding: 0 12px 0 0;
                            `}
                        >
                            <User>John Jameson</User>
                            <X width={12} height={12} />
                        </div>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-top: 12px;
                            color: var(--fg-medium);
                        `}
                    >
                        <div>Status</div>
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 200px;
                                height: 36px;
                                border-radius: 4px;
                                border: 1px solid var(--fg-300);
                                color: var(--fg-high);
                                line-height: 34px;
                                padding: 0 12px;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 8px;
                                `}
                            >
                                <InProgress width={16} height={16} />
                                In progress
                            </div>
                            <ChevronFilledRight
                                width={12}
                                height={12}
                                css={css`
                                    transform: rotate(90deg);
                                `}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
