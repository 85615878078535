import { css } from "@shiftx/styles"
import { ColorSchemeUSPType, colorSchemeUSP } from "./block/USP"
import { Label } from "./Text"

export const Badge = ({
    color,
    children,
}: {
    color: ColorSchemeUSPType
    children: any
}) => {
    const col = colorSchemeUSP[color]
    return (
        <Label
            css={css`
                width: fit-content;
                padding: 2px 8px;
                background-color: ${col.label.bg};
                border-radius: 4px;
                color: ${col.label.fg};
            `}
        >
            {children}
        </Label>
    )
}
